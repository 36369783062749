import React from 'react'
import { Feedback } from '@wf/ria/'
import {
	IconError,
	IconConfirmation,
	IconWarning,
	IconInformationalLight,
	IconTimeout,
	IconOutage
} from '@wf/ria-icons/'
import { FormattedMessage } from 'react-intl'

export function getSystemMessageIcon(iconKind) {
	switch (iconKind) {
		case 'error':
			return <IconError size="medium" theme="alert" />
		case 'warning':
			return <IconWarning size="medium" />
		case 'confirmation':
			return <IconConfirmation size="medium" />
		case 'informational':
			return <IconInformationalLight size="medium" />
		case 'timeout':
			return <IconTimeout size="medium" />
		case 'outage':
			return <IconOutage size="medium" />
		default:
			return null
	}
}

export function SystemMessages({ messages }) {
	if (messages.length === 0) {
		return null
	}
	return messages.map(message => (
		<SystemMessage
			messageId={message.messageId}
			iconKind={message.iconKind}
			show={message.show}
			className={message.className}
			values={message.values}
			key={message.messageId}
		/>
	))
}

export function renderMessage(message) {
	return <span role="alert">{message}</span>
}

export function SystemMessage({
	messageId,
	message,
	iconKind,
	show,
	className = '',
	values = {}
}) {
	if (show) {
		return (
			<div className={`SystemMessages ${className}`} role={'alert'}>
				<Feedback icon={getSystemMessageIcon(iconKind)}>
					<Message messageId={messageId} message={message} values={values} />
				</Feedback>
			</div>
		)
	}
	return null
}

export function Message({ messageId, values, message }) {
	if (messageId) {
		if (Array.isArray(messageId)) {
			const messageList = messageId.map((msg, index) => (
				<li key={index}>
					<FormattedMessage id={msg} values={values}>
						{renderMessage}
					</FormattedMessage>
				</li>
			))
			return <ul>{messageList}</ul>
		}
		return (
			<FormattedMessage id={messageId} values={values}>
				{renderMessage}
			</FormattedMessage>
		)
	} else {
		return <span>{message}</span>
	}
}
